<template>
  <div class="container mt ml mr">
    <div class="row" v-if="sources">
      <h2 style="font-family: 'Bebas Neue', cursive; font-size: 50px" :class="[ $store.state.locale == 'he' || $store.state.locale == 'ar' ? 'text-right' : 'text-left' ]">{{ $t('sources.sourcesUsed') }}</h2>
      <p class="align-left" v-if="!dbSources.length">{{ $t('sources.loading') }}</p>
      <div>
        <ul>
          <template v-for="(source, idx) in dbSources" :key="idx">
            <li v-html="createTitle(source)" class="word-break" :class="[ $store.state.locale == 'he' || $store.state.locale == 'ar' ? 'align-right' : 'align-left' ]"></li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
/* import config from '@/config'; */
import db1948 from '@/helpers/blob-1948.json';
import db1956 from '@/helpers/blob-1956.json';

import 'bootstrap/dist/css/bootstrap.min.css';
export default {
  name: 'Sources',
  data() {
    return {
      dbSources: [],
      dbEvents: [],
      sources: [],
      markers: this.$t('sources.markers'),
      ltr: this.$store.state.locale == 'he' || this.$store.state.locale == 'ar'
    };
  },
  async mounted() {
    this.checkCurrentWar()
    /* const res2 = await fetch(config.api + '/events');
    this.events = await res2.json(); */
    this.sourceIds = this.dbEvents.map((e) => e.source);

    /* const res = await fetch(config.api + '/sources');
    this.sources = await res.json(); */
    // sort sources after sourceIds occurance
    this.dbSources.sort((a, b) => {
      const aIdx = this.sourceIds?.filter((id) => id === a._id)?.length;
      const bIdx = this.sourceIds?.filter((id) => id === b._id)?.length;
      return bIdx - aIdx;
    });
  },
  methods: {
    checkCurrentWar: function () {
      switch (this.$store.state.war) {
        case '1948-arab-israeli-war':
          this.dbSources = db1948.sources;
          this.dbEvents = db1948.events;
          break;
        case 'suez-crisis':
          this.dbSources = db1956.sources;
          this.dbEvents = db1956.events;
          break;
      }
    },
    italic(content) {
      return '<i>' + content + '</i>';
    },
    createTitle(source) {
      source = this.dbSources.find((s) => s._id === source._id);
      const count = this.sourceIds?.filter((id) => id === source._id)?.length;
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const types = {
        website: `<b>“${source.title}”</b> ${source.author}, ${source.publisher}, ${
          source.accessDate.substr(8, 10) + ' ' + monthNames[source.accessDate.substr(5, 2).replace('0', '') - 1] + ' ' + source.accessDate.substr(0, 4)
        }, <a target="_blank" href="${source.url}">${source.url}</a>`,
        newspapers: '<b>“' + source.title + '”</b>. ' + this.italic(source.publisher) + ', ' + source.publishDate.substr(0, 4) + '.',
        book: source.author + '. <b>“' + source.title + '”</b> ' + this.italic(source.publisher) + ', ' + source.publishDate.substr(0, 4) + '.',
        journal: source.author + '. <b>“' + source.title + '”</b> ' + this.italic(source.publisher) + ', ' + source.publishDate.substr(0, 4) + '.',
      };
      return this.ltr ? count + this.markers + ' - ' + types[source.type] : types[source.type] + ' - ' + count + this.markers;
    },
  },
};
</script>

<style scoped lang="scss">
.ml,
.mr {
  margin-left: 20px;
  margin-right: 20px;
}

.mt {
  margin-top: 5em !important;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.word-break {
  word-break: break-all;
}
</style>
