<template>
  <div class="container mt">
    <div :class="[ $store.state.locale == 'he' || $store.state.locale == 'ar' ? 'about-rtl' : 'row' ]">
      <div class="col-md-7 col-xs-12">
        <!--Title + Description-->
        <h2 style="font-family: 'Bebas Neue', cursive; font-size: 50px" :class="[ $store.state.locale == 'he' || $store.state.locale == 'ar' ? 'text-right' : 'text-left' ]">{{ $t('modal.header') }}</h2>
        <p class="overflow-auto" :class="[ $store.state.locale == 'he' || $store.state.locale == 'ar' ? 'text-right' : 'text-left' ]" style="font-size: 20px;font-family: 'Roboto', sans-serif;">
          {{ $t('modal.textOne') }}<b>{{ $t('modal.projectWorldAtWar') }}</b>{{ $t('modal.textTwo') }}<b>{{ $t('modal.projectWorldAtWar') }}</b>{{ $t('modal.textThree') }}<br /><br />

          {{ $t('modal.textFour') }}<br /><br />

          {{ $t('modal.textFive') }}<br />
          {{ $t('modal.textSix') }}<br />
          {{ $t('modal.textSix2') }}<br />
          {{ $t('modal.textSeven') }}<br /><br />

          {{ $t('modal.textEight') }}<b>support@projectwaw.org</b>{{ $t('modal.textNine') }}<a href="https://discord.gg/dU84EgXu2T">{{ $t('modal.textTen') }}</a>{{ $t('modal.textEleven') }}<br /><br />

          {{ $t('modal.textTwelve') }}
        </p>
      </div>
      <!-- /.col-sm-6 -->
      <div class="col-md-5 col-xs-3">
        <!-- Centered image -->
        <img class="img-responsive center-block hidden-xs" src="../../public/WAW_Logo.webp" alt="" />
        <!-- Continue button -->
      </div>
      <!-- /.col-sm-6 -->
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css';
export default {};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto&display=swap');

.mt {
  margin-top: 5em !important;
}

.about-rtl {
  display: flex;
  flex-direction: row;
}
</style>
