<template>
  <div class="container mt">
    <!--Title + Description-->
    <h2 style="font-family: 'Bebas Neue', cursive; font-size: 60px" :class="[ $store.state.locale == 'he' || $store.state.locale == 'ar' ? 'text-right' : 'text-left' ]">{{ $t('legals.header') }}</h2>
    <h4 class="overflow-auto" :class="[ $store.state.locale == 'he' || $store.state.locale == 'ar' ? 'text-right' : 'text-left' ]">{{ $t('legals.update') }}</h4>
    <h3 style="font-family: 'Bebas Neue', cursive; font-size: 40px" :class="[ $store.state.locale == 'he' || $store.state.locale == 'ar' ? 'text-right' : 'text-left' ]">{{ $t('legals.secondHeader') }}</h3>
    <div class="overflow-auto" :class="[ $store.state.locale == 'he' || $store.state.locale == 'ar' ? 'text-right' : 'text-left' ]" style="font-size: 20px; font-family: 'Roboto', sans-serif">
      <h3>{{ $t('legals.t1') }}</h3>

      <p>
        {{ $t('legals.p1') }}
      </p>

      <h3>{{ $t('legals.t2') }}</h3>

      <p>
        {{ $t('legals.p2') }}
      </p>

      <ul>
        <li>{{ $t('legals.bp1') }}</li>
        <li>{{ $t('legals.bp2') }}</li>
        <li>{{ $t('legals.bp3') }}</li>
        <li>{{ $t('legals.bp4') }}</li>
        <li>{{ $t('legals.bp5') }}</li>
      </ul>

      <p>
        {{ $t('legals.p2Continuation') }}
      </p>

      <h3>{{ $t('legals.t3') }}</h3>

      <p>
        {{ $t('legals.p3') }}
      </p>

      <h3>{{ $t('legals.t4') }}</h3>

      <p>
        {{ $t('legals.p4') }}
      </p>

      <h3>{{ $t('legals.t5') }}</h3>

      <p>
        {{ $t('legals.p5') }}
      </p>

      <h3>{{ $t('legals.t6') }}</h3>

      <p>
        {{ $t('legals.p6') }}
      </p>

      <h3>{{ $t('legals.t7') }}</h3>

      <p>
        {{ $t('legals.p7') }}
      </p>

      <h3>{{ $t('legals.t8') }}</h3>

      <p>{{ $t('legals.p8') }}</p>

      <h3>{{ $t('legals.t9') }}</h3>

      <p>{{ $t('legals.p9') }}</p>
    </div>
    <br />
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css';
export default {};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto&display=swap');

.mt {
  margin-top: 5em !important;
}
</style>
